import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyDEi1fNc1PpfcUGJNuGd6pUxzf1rxn80-g',
  authDomain: 'codex-bautista-costa.firebaseapp.com',
  projectId: 'codex-bautista-costa',
  storageBucket: 'codex-bautista-costa.appspot.com',
  messagingSenderId: '130055178071',
  appId: '1:130055178071:web:62754676266003c2cb6012',
  measurementId: 'G-606GZ7D5PY',
};

firebase.initializeApp( config );

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const timeStamp = firebase.firestore.Timestamp;
export type TimeStamp = firebase.firestore.Timestamp;
export const fieldValue = firebase.firestore.FieldValue;
export type FieldValue = firebase.firestore.FieldValue;
export const authPersistenceLocal = firebase.auth.Auth.Persistence.LOCAL;
export const authPersistenceSession = firebase.auth.Auth.Persistence.SESSION;
export const emailAuthProvider = firebase.auth.EmailAuthProvider;
